@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&family=Quicksand:wght@400;500;600&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

.container {
  margin: 0 auto;
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;
  max-width: 1230px;
}

.hero-container {
  padding-left: 16px;
  padding-right: 16px;
  width: 100vw;
  max-width: 1230px;
  margin: 0 auto;
}

.small-title {
  font-size: 12.5px;
  color: #225bbc;
  font-family: Quicksand;
  font-weight: 600;
}

hero-title {
  margin-bottom: 10px;
  font-size: 37.5px;
  color: #0e244b;
  font-family: Poppins;
  font-weight: 500;
  line-height: 46px;
  margin-top: 6px;
}

.large-title {
  font-size: 35px;
  color: #0e244b;
  font-family: Poppins;
  font-weight: 500;
}

.stats-value {
  font-size: 50px;
  font-family: Quicksand;
  font-weight: 600;
  color: #ffffff;
  line-height: 100px;
}
.stats-title {
  font-size: 16px;
  font-family: Quicksand;
  color: #ffffff;
  text-align: center;
}

@media screen and (min-width: 768px) {
  .small-title {
    font-size: 20px;
  }

  .large-title {
    font-size: 45px;
  }
  hero-title {
    font-size: 50px;
    line-height: 65px;
  }
  .stats-value {
    font-size: 80px;
  }
  .stats-title {
    font-size: 18px;
  }
}

.typed-cursor {
  opacity: 1;
  -webkit-animation: blink 0.7s infinite;
  -moz-animation: blink 0.7s infinite;
  animation: blink 0.7s infinite;
}
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.Mui-active,
.Mui-completed {
  margin-top: 0 !important;
  color: #225bbc !important;
  font-family: "Poppins" !important;
  font-size: 1rem !important;
}

.Mui-disabled {
  margin-top: 0 !important;
  font-family: "Poppins" !important;
  font-size: 1rem !important;
}
